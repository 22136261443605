import * as React from 'react';
import { Link } from 'react-router-dom';
import List from "../List/List";
import {useEffect, useState} from "react";
import {getPlacesData, getWeatherData} from "../../api/travelAdvisorAPI";
import {useLocation} from "react-router";
import hero from '../../assets/images/hero_600w_400h.png'
import sleep from '../../assets/images/sleep.png'
import eat from '../../assets/images/eat.png'
import tour from '../../assets/images/tour.png'


export default function CategoryDetail(props) {

	const location = useLocation();
	let banner = "/static/media/" + location.state.category + ".png";
	if(location.state.category == "sleep")
		banner = sleep;
	else if(location.state.category == "restaurants")
		banner = eat;
	else
		banner = tour;
	console.log(location);

	return (
		<>
			<br /><br />
			<h2>{location.state.text}</h2>

			<div className="left-grid-container">
				<div className="image-and-search-container">
					<div className="img-main">
						<img className="pg-image" src={banner} alt="A beach image" />
					</div>
				</div>

				<h5>Filters</h5>

				<div className="menu">
					<div className="menu-item">
						<a href="#">
							<div className="menu-icon">
								<i className="icon fa-solid fa-bed fa-xl" ></i>
							</div>
							<h3>Hotels</h3>
						</a>
					</div>
				</div>
			</div>

			{/*<List*/}
			{/*	isLoading={isLoading}*/}
			{/*	childClicked={childClicked}*/}
			{/*	places={filteredPlaces.length ? filteredPlaces : places}*/}
			{/*	type={type}*/}
			{/*	setType={setType}*/}
			{/*	rating={rating}*/}
			{/*	setRating={setRating}*/}
			{/*/>*/}
		</>
	);
}