import * as React from 'react';
import { Link } from 'react-router-dom';
import List from "../List/List";
import {useEffect, useState} from "react";
import {getPlacesData, getWeatherData} from "../../api/travelAdvisorAPI";
import {useNavigate} from "react-router";
import hero from '../../assets/images/hero_600w_400h.png'

export default function Menu() {
	const [type, setType] = useState('restaurants');
	const [rating, setRating] = useState('');

	const [coords, setCoords] = useState({});
	const [bounds, setBounds] = useState(null);

	const [weatherData, setWeatherData] = useState([]);
	const [filteredPlaces, setFilteredPlaces] = useState([]);
	const [places, setPlaces] = useState([]);

	const [autocomplete, setAutocomplete] = useState(null);
	const [childClicked, setChildClicked] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate()
	const data = { name: 'John', age: 30 };

	function handleClick() {
		navigate("/category", { replace: true, state: { bookName: "Fake Title" } })
	}


	//------------------------------------------------------------------
	useEffect(() => {
		navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
			setCoords({ lat: latitude, lng: longitude });
		});
	}, []);

	useEffect(() => {
		const filtered = places?.filter((place) => Number(place.rating) > rating);

		setFilteredPlaces(filtered);
	}, [rating]);

	useEffect(() => {
		if (bounds) {
			setIsLoading(true);

			getWeatherData(coords.lat, coords.lng)
				.then((data) => setWeatherData(data));

			getPlacesData(type, bounds.sw, bounds.ne)
				.then((data) => {
					setPlaces(data.filter((place) => place.name && place.num_reviews > 0));
					setFilteredPlaces([]);
					setRating('');
					setIsLoading(false);
				});
		}
	}, [bounds, type]);


	return (
		<>
			<div className="left-grid-container">
				<div className="image-and-search-container">
					<div className="img-main">
						<img className="pg-image" src={hero} alt="A beach image" />
					</div>
					<div className="search-area">
						<button className="btn" type="submit">
							<input type="text" name="" placeholder="Search" />
							<i className="fa-solid fa-magnifying-glass fa-flip-horizontal fa-lg"></i>
						</button>
					</div>
				</div>
				<div className="menu">
					{/*<div className="menu-item" onClick={handleClick}>*/}
					<div className="menu-item">
						<Link to="/category" state={{category: "sleep", text: "Accommodation"}}>
							<div className="menu-icon">
								<i className="icon fa-solid fa-bed fa-xl" ></i>
							</div>
							<h3>Sleep</h3>
						</Link>
					</div>

					<div className="menu-item">
						<Link to="/category" state={{category: "restaurants", text: "Restaurants/Eateries"}}>
							<div className="menu-icon">
								<i className="icon fa-solid fa-bed fa-xl" ></i>
							</div>
							<h3>Eat & Drink</h3>
						</Link>
					</div>

					<div className="menu-item">
						<Link to="/category" state={{category: "tour", text: "Tourist Sites"}}>
							<div className="menu-icon">
								<i className="icon fa-solid fa-bed fa-xl" ></i>
							</div>
							<h3>Tourist Sites</h3>
						</Link>
					</div>

				</div>
			</div>

			{/*<List*/}
			{/*	isLoading={isLoading}*/}
			{/*	childClicked={childClicked}*/}
			{/*	places={filteredPlaces.length ? filteredPlaces : places}*/}
			{/*	type={type}*/}
			{/*	setType={setType}*/}
			{/*	rating={rating}*/}
			{/*	setRating={setRating}*/}
			{/*/>*/}
		</>
	);
}