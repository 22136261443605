import React, { useRef, useEffect, useState } from 'react';
// import mapboxgl from 'mapbox-gl';
import '../../App.css';

/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


// import GoogleMapReact from 'google-map-react';
// import { Paper, Typography, useMediaQuery } from '@material-ui/core';
// import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
// import Rating from '@material-ui/lab/Rating';
// import mapStyles from '../../mapStyles';
// import useStyles from './styles.js';


const Map = () => {
  mapboxgl.accessToken = 'pk.eyJ1IjoiaG9sc2luc2EiLCJhIjoiY2xneGNkOTdvMDBqZDNxczkxenNmdHZjcSJ9.UxllI-yQ6OieEH91Gzpq4w';

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(138);
  const [lat, setLat] = useState(-34);
  const [zoom, setZoom] = useState(7);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });
  });

  return (
      <div>
        <div ref={mapContainer} className="map-container" />
      </div>
  );

};

export default Map;
